import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { Textfit } from "react-textfit"

import {
  LinkedinShareButton,
  LinkedinIcon,
  EmailIcon,
  EmailShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  FacebookIcon,
  FacebookShareButton,
} from "react-share"
import {
  motion,
  useViewportScroll,
  useTransform,
  useSpring,
} from "framer-motion"
import Layout from "../layouts/layout"
import SEO from "../components/seo"

import variables from "../styles/variables.scss"

import WhiteForm from "../components/whiteForm"

import postStyle from "./blog-post.module.scss"

export default function BlogPostTemplate({ location, data }) {
  const { scrollYProgress } = useViewportScroll()
  const post = data.contentfulBlogPost // get(this.props, 'data.contentfulBlogPost')
  //   const siteTitle = data.site.siteMetadata.title //get(this.props, 'data.site.siteMetadata.title')
  // console.log(scrollYProgress)
  const [indicator, setindicator] = useState(0)
  const yRange = useTransform(scrollYProgress, [0, 1], [0, 100])
  const pathLength = useSpring(yRange, { stiffness: 5, damping: 1 })

  useEffect(() => scrollYProgress.onChange(x => setindicator(x)), [
    scrollYProgress,
  ])

  return (
    <Layout loc={location}>
      <SEO
        title={post.title}
        description={post.description.childMarkdownRemark.excerpt}
      ></SEO>
      <div
        className="hoi"
        style={{
          position: "fixed",
          backgroundColor: "#1f375c",
          width: `${indicator * 100}vw`,
          height: "1rem",
        }}
      ></div>

      <div className={postStyle.container} style={{ background: "#fff" }}>
        {/* {console.log(post.image)} */}
        <div
          className={postStyle.banner}
          style={
            {
              // backgroundImage: `url(${post.image.fluid.srcWebp})`,
            }
          }
        >
          <Img className={postStyle.image} fluid={post.image.fluid} />
          {/* <h1 className={postStyle.title}>{preview.fields.title}</h1> */}
          <Textfit className={postStyle.title} mode="multi" max={400}>
            {post.title}
            <span className={postStyle.dot}>.</span>
          </Textfit>
          <p className={postStyle.date}>{post.publishDate}</p>
        </div>
        <div className={postStyle.wrapper}>
          {/* <h1 className={postStyle.title}>{post.title}</h1> */}
          {/* <p
            style={{
              display: "block",
            }}
          >
            {post.publishDate}
          </p> */}
          <div
            className={postStyle.body}
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />

          <LinkedinShareButton url={`https://brcg.nl/blog/${post.slug}`}>
            <LinkedinIcon size={32} />
          </LinkedinShareButton>
          <EmailShareButton
            url={`https://brcg.nl/blog/${post.slug}`}
            subject={`${post.title} - Business Resilience Consultancy Group`}
          >
            <EmailIcon size={32} />
          </EmailShareButton>
          <TwitterShareButton url={`https://brcg.nl/blog/${post.slug}`}>
            <TwitterIcon size={32} />
          </TwitterShareButton>
          <WhatsappShareButton url={`https://brcg.nl/blog/${post.slug}`}>
            <WhatsappIcon size={32} />
          </WhatsappShareButton>
          <FacebookShareButton url={`https://brcg.nl/blog/${post.slug}`}>
            <FacebookIcon size={32} />
          </FacebookShareButton>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      description {
        childMarkdownRemark {
          excerpt
        }
      }
      publishDate(formatString: "DD MMM YYYY", locale: "nl-NL")
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          url
        }

        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

{
  /* <svg
      style={{
        position: "fixed",
        marginTop: "4em",
        transform: "translateX(-1.2em)",
      }}
    >
      
      <motion.path
        fill="none"
        strokeWidth="10"
        stroke="white"
        strokeDasharray="0 1"
        d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
        style={{
          pathLength,
          rotate: 90,
          translateX: 5,
          translateY: 5,
          scaleX: -1, // Reverse direction of line animation
        }}
      />
    </svg> */
}
